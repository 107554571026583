import { useMemo } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import type { SubscriptionWidgetAdapter } from '@sevenrooms/core/api'
import { z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useMultiSelectTagOptionForm, useBirthdayDayInputForm, useBirthdayMonthInputForm } from '@sevenrooms/core/ui-kit/form'
import { subscriptionWidgetMessages } from '../subscriptionWidgetMessages'

type useWidgetSubscriptionFormProps = ReturnType<typeof SubscriptionWidgetAdapter.settingsToClient>['formSettings']

export function useWidgetSubscriptionForm({
  isRecaptcha,
  isSalutation,
  isBirthday,
  isPolicyVenueMarketing,
  isPolicyVenueMarketingDefaultOn,
  isPolicyVenueGroupMarketing,
  isPolicyVenueGroupMarketingDefaultOn,
  isPostalCode,
  isPolicySmsVenueMarketing,
  isPolicySmsVenueMarketingDefaultOn,
}: useWidgetSubscriptionFormProps) {
  const { formatMessage } = useLocales()
  const birthdayDay = useBirthdayDayInputForm(!isBirthday)
  const birthdayMonth = useBirthdayMonthInputForm(!isBirthday)
  const multiSelectTagOptionForm = useMultiSelectTagOptionForm()

  return useMemo(() => {
    const form = z
      .object({
        // required
        firstName: z
          .string()
          .min(1, { message: formatMessage(subscriptionWidgetMessages.resWidgetErrorsFieldRequired) })
          .default(''),
        lastName: z
          .string()
          .min(1, { message: formatMessage(subscriptionWidgetMessages.resWidgetErrorsFieldRequired) })
          .default(''),
        // customizable
        email: z.string().email(formatMessage(subscriptionWidgetMessages.subWidgetInvalidEmail)).nullable().default(null),
        phone: z.string().nullable().default(null),
        phoneLocale: z.string().nullable().default(null),
        birthdayDay,
        birthdayMonth,
        dietaryRestrictions: multiSelectTagOptionForm,
        optInMarketingVenue: isPolicyVenueMarketing ? z.boolean().default(isPolicyVenueMarketingDefaultOn) : z.boolean().optional(),
        optInMarketingVenueGroup: isPolicyVenueGroupMarketing
          ? z.boolean().default(isPolicyVenueGroupMarketingDefaultOn)
          : z.boolean().optional(),
        optInVenueSmsMarketing: isPolicySmsVenueMarketing
          ? z.boolean().default(isPolicySmsVenueMarketingDefaultOn)
          : z.boolean().optional(),
        postalCode: isPostalCode
          ? z
              .string()
              .min(1, { message: formatMessage(subscriptionWidgetMessages.resWidgetErrorsFieldRequired) })
              .default('')
          : z.string().nullable().default(null),
        recaptcha: isRecaptcha
          ? z
              .string()
              .min(1, { message: formatMessage(subscriptionWidgetMessages.resWidgetErrorsFieldRequired) })
              .default('')
          : z.string().nullable().default(null),
        salutation: isSalutation
          ? z
              .string()
              .min(1, { message: formatMessage(subscriptionWidgetMessages.resWidgetErrorsFieldRequired) })
              .default('')
          : z.string().default(''),
      })
      .refine(
        ({ optInMarketingVenue, optInMarketingVenueGroup, optInVenueSmsMarketing }) =>
          optInMarketingVenue || optInMarketingVenueGroup || optInVenueSmsMarketing,
        {
          message: formatMessage(subscriptionWidgetMessages.subWidgetEmptyOptIn),
          path: ['optInMarketingVenue'],
        }
      )
      .refine(({ optInMarketingVenue, optInMarketingVenueGroup, email }) => !(optInMarketingVenue || optInMarketingVenueGroup) || !!email, {
        message: formatMessage(subscriptionWidgetMessages.resWidgetErrorsFieldRequired),
        path: ['email'],
      })
      .superRefine(({ optInVenueSmsMarketing, phone, phoneLocale }, ctx) => {
        if (!!optInVenueSmsMarketing && !phone) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(subscriptionWidgetMessages.resWidgetErrorsFieldRequired),
            path: ['phone'],
          })
        }
        if (!!phone && !_isValidPhoneNumber(phone, phoneLocale)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(subscriptionWidgetMessages.subWidgetInvalidPhoneNumber),
            path: ['phone'],
          })
        }
      })

    return form
  }, [
    formatMessage,
    birthdayDay,
    birthdayMonth,
    multiSelectTagOptionForm,
    isPolicyVenueMarketing,
    isPolicyVenueMarketingDefaultOn,
    isPolicyVenueGroupMarketing,
    isPolicyVenueGroupMarketingDefaultOn,
    isPolicySmsVenueMarketing,
    isPolicySmsVenueMarketingDefaultOn,
    isPostalCode,
    isRecaptcha,
    isSalutation,
  ])
}

const _isValidPhoneNumber = (phoneNumber: string | null, phoneNumberLocale: string | null) => {
  if (!phoneNumber || !phoneNumberLocale) {
    return false
  }
  return isValidPhoneNumber(phoneNumber)
}
