import { Provider } from 'react-redux'
import { Router, Route, Switch } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { WidgetComponent } from './components/SubscriptionWidget'
import { store } from './store'

export function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path={routes.explore.subscription.path}>
            <WidgetComponent />
          </Route>
        </Switch>
      </Router>
    </Provider>
  )
}
